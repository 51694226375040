<template>
  <div>
    <div v-infinite-scroll="load" infinite-scroll-delay class="infinite-list" style="overflow: auto" :style="'height:'+height">
      <div v-for="(item,index) in tableData" :key="index">
        <div class="item-info" @click="handleRowDbClick(item)">
          <div class="process-definition-name">
            {{item.startUser.name}}的{{item.processDefinitionName}}
          </div>
          <div class="item-tate">
            <div>已经停留</div>
            <div>{{distance(item.taskCreatedTime)}}</div>
          </div>
          <div class="item-tate">
            <div>申请时间</div>
            <div>{{formatTime(item,{property: "startTime"})}}</div>
          </div>
        </div>
      </div>
      <div v-if="query.total == null || query.total == 0" style="display: flex;align-content: center;justify-content: center;height: 100%;">
        <el-empty :description="loading == true ? '加载中...' :'暂无需要审批的数据！'" :image-size="120"/>
      </div>
      <div v-else>
        <div class="no-data" v-if="tableData.length >= query.total"> --- 到底啦 ---</div>
        <div v-else style="height: 50px" v-loading="loading"></div>
      </div>

    </div>
  </div>
</template>
<script>
import { todoList } from "@/api/design";
import {formatTime, formatBusinessStatus, distance} from "../form";
export default {
  data() {
    return {
      tableData: [],
      loading: true,
      height:"400px",
      query:{
        total: null,
        pageNo: 1,
        pageSize: 10,
        currentUserInfo:null,
      }
    };
  },
  methods: {
    load(){
      if (this.query.total==null || this.tableData.length < this.query.pageSize){return;}
      this.query.pageNo = this.query.pageNo+1;
      this.getTodoList();
    },
    getTodoList() {
      if (this.query.total!=null && this.tableData.length >= this.query.total){return;}
        this.loading = true;
        todoList(this.query).then((rsp) => {
          let list = rsp.data.result.records;
          this.query.total = rsp.data.result.total;
          this.tableData.push(...list);
        })
        .finally(() => {
            this.loading = false;
        });
    },
    formatTime,distance,
    formatBusinessStatus,
    handleRowDbClick(row) {
      this.$router.push({
        path: "/workspaceMini/process/instance/tabs",
        query: {
          processInstanceId: row.processInstanceId,
          taskId: row.taskId,
          type: "todoTask",
        },
      });
    },
  },
  mounted() {
    this.getTodoList();
  },
  created() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = `${h - 130 - 35}px`;
    const str = localStorage.getItem("user");
    const user = JSON.parse(str);
    user.id = user.id.toString();
    this.query.currentUserInfo = user;
  }
};
</script>
<style lang="less" scoped>
.infinite-list{
  padding: 0;
  margin: 0 auto;
  list-style: none;
}
.item-info{
  width: 90%;
  min-width: 250px !important;
  max-width: 400px !important;
  margin: 10px auto;
  padding: 10px;
  border-radius: 10px;
  background-color: #FAFCFF;
}
::-webkit-scrollbar {
  width: 0px; /* 滚动条宽度 */
}
::-webkit-scrollbar-thumb {
  background: #cecece; /* 滚动条颜色 */
  border-radius: 5px; /* 圆角 */
}
::-webkit-scrollbar-thumb:hover {
  background: #909090; /* 悬停时的颜色 */
}
.process-definition-name{
  font-weight: bold;
  text-align: center;
  font-size: 15px;
  margin-bottom: 10px;
}
.no-data{
  text-align: center;
  font-size: 12px;
  margin: 20px
}
.item-tate{
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin: 1px 10px
}
</style>